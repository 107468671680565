import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import AudioPlayer from "../../../components/audio-player"
import Constrain from "../../../components/constrain"
import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Image from "../../../components/image"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

import LeichteSpracheNext from "../../../content/shared/leichte-sprache-next"

const LeichteSpracheFamilie = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheFamilieIndexQuery {
      mutter: file(relativePath: { eq: "leichte-sprache/mutter.jpg" }) {
        ...largeImage
      }
      vater: file(relativePath: { eq: "leichte-sprache/vater.jpg" }) {
        ...largeImage
      }
      pfarrer: file(relativePath: { eq: "leichte-sprache/pfarrer.jpg" }) {
        ...smallImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Mehr über Friedrich Hölderlin"
        description="Hölderlin wird am 20. März 1770 geboren. Sein ganzer Name ist Johann Christian Friedrich Hölderlin. Er kommt in der Stadt Lauffen am Neckar zur Welt."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Über Hölderlin",
                link: "/leichte-sprache/ueber-hoelderlin",
              },
              {
                title: "Familie",
                link: "/leichte-sprache/ueber-hoelderlin/familie",
              },
            ]}
          />
          <PageTitle>Hölderlins Familie</PageTitle>
          <Paragraph dropcap={true}>
            Hölderlins Mutter heißt Johanna Christiana Hölderlin. <br />
            Sein Vater heißt Heinrich Friedrich Hölderlin. <br />
            Hölderlins Vater stirbt sehr früh. <br />
            Da ist Friedrich Hölderlin erst 2 Jahre alt.
          </Paragraph>
          <Constrain>
            <AudioPlayer src="leichte-sprache/03-familie" />
          </Constrain>
          <Grid columns={[1, 2]} space={[6, 3]}>
            <Image
              image={data.mutter.childImageSharp.gatsbyImageData}
              attribution="DLA Marbach"
              alt="Hölderlins Mutter hat ein schmales Gesicht. Ihre Haare sind nach oben gesteckt. Sie trägt ein kostbares Kleid. Es hat ein Muster aus kleinen braunen und orangenen Blumen. Das Kleid ist oben ausgeschnitten und hat weiße Stickränder."
              caption="Hölderlins Mutter"
            />
            <Image
              image={data.vater.childImageSharp.gatsbyImageData}
              attribution="DLA Marbach"
              alt="Hölderlins Vater hat ein rundes Gesicht. Er trägt eine graue Perücke mit hochgewickelten Locken. Er ist gekleidet mit einem blauen Jackett mit weißen Knöpfen. Darunter trägt er ein weißes Hemd mit aufgestickten Verzierungen in den Farben Gelb, Rot und Blau."
              caption="Hölderlins Vater"
            />
          </Grid>
          <Paragraph>
            Seine Mutter heiratet dann wieder. <br />
            Da ist Friedrich Hölderlin 4 Jahre alt. <br />
            Sein Stief∙vater heißt Johann Christoph Gok. <br />
            Der Stief∙vater stirbt auch früh. <br />
            Da ist Friedrich Hölderlin 9 Jahre alt.
          </Paragraph>
          <Paragraph>
            Friedrich Hölderlin hat 2 Geschwister. <br />
            Seine Schwester heißt Maria Eleonora Heinrica Hölderlin. <br />
            Sein Bruder heißt Karl Christoph Friedrich Gok. <br />
            Karl ist Hölderlins Halb∙bruder. <br />
            Halb∙bruder bedeutet: <br />
            Zwei Geschwister haben nicht den gleichen Vater. <br />
            Oder sie haben nicht die gleiche Mutter. <br />
            Friedrich Hölderlin hat einen anderen Vater als sein Bruder Karl.{" "}
            <br />
            Trotzdem sind sie Geschwister.
          </Paragraph>
        </Stack>
        <LeichteSpracheNext variant="pfarrer" />
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheFamilie
